exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-new-cv-review-tsx": () => import("./../../../src/pages/new/cv-review.tsx" /* webpackChunkName: "component---src-pages-new-cv-review-tsx" */),
  "component---src-pages-new-index-tsx": () => import("./../../../src/pages/new/index.tsx" /* webpackChunkName: "component---src-pages-new-index-tsx" */),
  "component---src-pages-purchase-[plancode]-tsx": () => import("./../../../src/pages/purchase/[plancode].tsx" /* webpackChunkName: "component---src-pages-purchase-[plancode]-tsx" */),
  "component---src-pages-purchase-failed-index-tsx": () => import("./../../../src/pages/purchase/failed/index.tsx" /* webpackChunkName: "component---src-pages-purchase-failed-index-tsx" */),
  "component---src-pages-purchase-processing-index-tsx": () => import("./../../../src/pages/purchase/processing/index.tsx" /* webpackChunkName: "component---src-pages-purchase-processing-index-tsx" */),
  "component---src-pages-purchase-successful-index-tsx": () => import("./../../../src/pages/purchase/successful/index.tsx" /* webpackChunkName: "component---src-pages-purchase-successful-index-tsx" */),
  "component---src-pages-upsell-tsx": () => import("./../../../src/pages/upsell.tsx" /* webpackChunkName: "component---src-pages-upsell-tsx" */),
  "component---src-templates-account-history-tsx": () => import("./../../../src/templates/accountHistory.tsx" /* webpackChunkName: "component---src-templates-account-history-tsx" */),
  "component---src-templates-critique-tsx": () => import("./../../../src/templates/critique.tsx" /* webpackChunkName: "component---src-templates-critique-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-landing-page-v-2-tsx": () => import("./../../../src/templates/landingPageV2.tsx" /* webpackChunkName: "component---src-templates-landing-page-v-2-tsx" */),
  "component---src-templates-press-article-tsx": () => import("./../../../src/templates/pressArticle.tsx" /* webpackChunkName: "component---src-templates-press-article-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/productPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */)
}

